<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Profile Settings</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users-add">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body">
            <el-tabs v-model="activeTab" @tab-click="handleTabClick">
              <el-tab-pane label="Profile" name="profile">
                <form @submit.prevent="handleProfileSubmission()" method="POST" novalidate>
                  <div>
                    <div class="form-row">
                      <div class="form-group required col-md-6">
                        <label for="username" class="control-label">Username</label>
                        <input autocomplete="off" type="text" id="username" name="username" v-model="profile.username"
                               v-validate="'required'" :class="{'has-error' : errors.has('username')}"
                               class="form-control" placeholder="Username" disabled>
                        <div class="help text-danger" v-show="errors.has('username')">
                          {{ errors.first('username') }}
                        </div>
                      </div>
                      <div class="form-group required col-md-6">
                        <label for="name" class="control-label">Name</label>
                        <input type="text" id="name" name="name" v-model.trim="profile.name" class="form-control"
                               :class="{'has-error' : errors.has('name')}"
                               placeholder="Name">
                        <div class="help text-danger" v-show="errors.has('name')">
                          {{ errors.first('name') }}
                        </div>
                      </div>
                      <div class="form-group required col-md-6">
                        <label for="mobile_no" class="control-label">Mobile No.</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-phone"></i></span>
                          </div>
                          <input type="text" id="mobile_no" class="form-control" name="mobile_no"
                                 placeholder="Mobile No"
                                 :class="{'has-error' : errors.has('mobile_no')}" v-model="profile.mobile_no">
                        </div>
                        <div class="help text-danger" v-show="errors.has('mobile_no')">
                          {{ errors.first('mobile_no') }}
                        </div>
                      </div>
                      <div class="form-group required col-md-12">
                        <label for="email" class="control-label">Email</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                          </div>
                          <input type="text" id="email" name="email" v-model="profile.email"
                                 class="form-control" :class="{'has-error' : errors.has('email')}"
                                 placeholder="Email">
                        </div>
                        <small class="form-text text-muted">
                          <i class="fa fa-info-circle"></i>&nbsp;We'll never share your email with anyone else.
                        </small>
                        <div class="help text-danger" v-show="errors.has('email')">
                          {{ errors.first('email') }}
                        </div>
                      </div>

                      <div class="col-md-12">
                        <h4 class="site-heading my-3"><span>Avatar</span></h4>
                      </div>
                      <div class="form-group col-md-12">
                        <label for="avatar"></label>
                        <div class="row align-items-center">
                          <div class="col-4">
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" name="avatar" class="custom-file-input" id="avatar"
                                       @change="selectFile" accept="image/x-png,image/jpg,image/jpeg">
                                <label class="custom-file-label">Choose file</label>
                              </div>

                            </div>
                          </div>
                          <div class="col-8">
                            <img alt="preview image" v-if="profile.avatar" v-bind:src="profile.avatar" width="100"
                                 height="100" class="img-circle"/>
                          </div>
                          <div class="help text-danger" v-show="errors.has('avatar')">
                            {{ errors.first('avatar') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="submit" v-if="!is_edit" class="btn btn-site">Submit</button>
                    <button type="submit" v-if="is_edit" class="btn btn-site">Update</button>
                  </div>
                </form>
              </el-tab-pane>
              <el-tab-pane label="Change Password" name="change_password">
                <form @submit.prevent="handleUpdatePasswordSubmission()" method="POST" novalidate>
                  <div>
                    <div class="form-row">
                      <div class="form-group required col-md-12">
                        <label for="old_password" class="control-label">Old Password</label>
                        <input autocomplete="off" type="password" id="old_password" name="old_password"
                               v-model="changed_pass.old_password"
                               :class="{'has-error' : errors.has('old_password')}"
                               class="form-control" placeholder="old password">
                        <div class="help text-danger" v-show="errors.has('old_password')">
                          {{ errors.first('old_password') }}
                        </div>
                      </div>
                      <div class="form-group required col-md-12">
                        <label for="password" class="control-label">Password</label>
                        <input autocomplete="off" type="password" id="password" name="password"
                               v-model="changed_pass.password"
                               :class="{'has-error' : errors.has('password')}"
                               class="form-control" placeholder="new password">
                        <div class="help text-danger" v-show="errors.has('password')">
                          {{ errors.first('password') }}
                        </div>
                      </div>
                      <div class="form-group required col-md-12">
                        <label for="password_confirmation" class="control-label">Confirm Password</label>
                        <input autocomplete="off" type="password" id="password_confirmation"
                               name="password_confirmation"
                               v-model="changed_pass.password_confirmation"
                               :class="{'has-error' : errors.has('password_confirmation')}"
                               class="form-control" placeholder="confirm password">
                        <div class="help text-danger" v-show="errors.has('password_confirmation')">
                          {{ errors.first('password_confirmation') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="submit" class="btn btn-site">Update Password</button>
                  </div>
                </form>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from "vuex";
import {AuthAPI} from "@/services/api";

export default {
  name: 'AuthProfile',
  metaInfo: {
    title: 'Profile | Dotlines'
  },
  components: {},
  computed: {
    ...mapGetters(["profile"])
  },
  data: () => ({
    is_edit: true,
    changed_pass: {},
    is_avatar: false,
    isSelectorLoading: false,
    is_loading: false,
    activeTab: 'profile'
  }),
  methods: {
    ...mapActions(['FETCH_PROFILE']),
    handleProfileSubmission() {
      let _this = this;
      _this.$validator.validateAll().then((result) => {
        if (result) {
          _this.is_loading = true;
          let api_url = '/admin_users/' + _this.profile.id;
          if (!_this.is_avatar) {
            _this.$delete(_this.profile, 'avatar');
          }
          axios({
            method: 'PUT', url: api_url,
            data: _this.profile,
            headers: {"Content-Type": "application/json"},
          }).then(function (response) {
            _this.$toaster.success(response.data.message)
            _this.errors.clear()
            _this.move({name: 'AuthProfile'})
          }).catch((errors) => {
            if (errors.response.status === 500) {
              _this.$toaster.error(errors.response.data.message)
            }
            _this.$setErrorsFromResponse(errors.response.data);
          }).finally(() => {
            _this.FETCH_PROFILE();
            _this.is_avatar = false;
            _this.is_loading = false;
          });
        }
      })
    },
    handleUpdatePasswordSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          AuthAPI.changePassword(this.changed_pass).then(response => {
            this.errors.clear()
            this.$toaster.success(response.message)
          }).catch(error => {
            this.$setErrorsFromResponse(error.data);
          }).finally(() => {
            this.changed_pass = {};
            this.is_loading = false;
          });
        }
      })
    },
    selectFile: function (event) {
      // `files` is always an array because the file input may be in multiple mode
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.is_avatar = true;
        this.profile.avatar = reader.result;
      };
    },
    move(params) {
      // get comparable fullPaths
      let from = this.$route.fullPath
      let to = this.$router.resolve(params).route.fullPath

      if (from === to) {
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return;
      }
      this.$router.push(params);
    },
    handleTabClick() {

    }
  },
}
</script>
